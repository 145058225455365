<template>
  <div>
    <report-section-title>Resultados Generales</report-section-title>
    <p class="applicants">{{ data.length }} Aplicantes</p>
    <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { GChart } from "vue-google-charts";

import { formatAmount } from "@/utils/numbers.js";

export default defineComponent({
  name: "LeaderGeneralGraph",
  components: {
    GChart
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartOptions: {
        legend: "none",
        vAxis: { title: "Promedio de Respuesta" },
        hAxis: { showTextEvery: 1 },
        bars: "vertical",
        chartArea: { width: "80%", height: "70%" },
        height: 700,
      },
    };
  },
  methods: {
    calculateAverage(property) {
      return this.data.reduce(
        (total, applicant) =>
          total + applicant.results.applicantCount[property],
        0
      ) / this.data.length;
    }
  },
  computed: {
    chartData() {
      const data = [
        ["Aspecto", "Promedio", { role: "style" }, { role: "annotation" }]
      ];

      const emotionsAverage = this.calculateAverage("emotionsum");
      const mindAverage = this.calculateAverage("mindsum");
      const behaviorAverage = this.calculateAverage("behaviorsum");

      const averages = [emotionsAverage, mindAverage, behaviorAverage];
      const colors = ["#cda349", "#37526d", "#9edbdd"];

      const aspects = ["Emoción", "Mente", "Acciones"];

      for (let i = 0; i < aspects.length; i++) {
        data.push([aspects[i], averages[i], colors[i], formatAmount(averages[i])]);
      }

      return data;
    },
  }
});
</script>

<style scoped>
div {
  font-family: "Roboto", sans-serif;
  background-color: white;
  padding-bottom: 10px;
}
.applicants {
  font-size: 1rem;
  font-weight: bold;
  text-align: right;
  margin-right: 10%;
  margin-bottom: 0%;
}
</style>
