<template>
  <div>
    <report-section-title>Acciones</report-section-title>
    <p class="applicants">{{ data.length }} Aplicantes</p>
    <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { GChart } from "vue-google-charts";

import questions from "@/model/self-leadership/questions.js";
import { formatAmount } from "@/utils/numbers.js";

const sectionQuestions = questions.find(({ key }) => key === "behavior");

const BAR_COLOR = "#9edbdd";

export default defineComponent({
  name: "LeaderBehaviorGraph",
  components: {
    GChart
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartOptions: {
        legend: "none",
        vAxis: { title: "Promedio de Respuesta" },
        hAxis: { showTextEvery: 1 },
        bars: "vertical",
        chartArea: { width: "80%", height: "70%" },
        height: 700,
      },
    };
  },
  computed: {
    chartData() {
      const data = [
        [
          "Pregunta",
          "Promedio de Respuesta",
          { role: "style" },
          { role: "annotation" }
        ]
      ];

      sectionQuestions.items.forEach((question, index) => {
        const average = this.data.reduce(
          (total, { results: { behavior } }) => total + (behavior[index] || 0),
          0
        ) / this.data.length;

        data.push([question, average, BAR_COLOR, formatAmount(average)]);
      });

      return data;
    }
  }
});
</script>

<style scoped>
div {
  font-family: "Roboto", sans-serif;
  background-color: white;
  margin: 0 auto;
}
.applicants {
  font-size: 1rem;
  font-weight: bold;
  text-align: right;
  margin-right: 10%;
  margin-bottom: 0%;
}
</style>
